.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.signup-page:after {
  position: absolute;
  content: "";
  background: #ffb600;
  width: 100%;
  height: 100vh;
  transform: skew(-23deg);
  left: 109px;
  top: 0;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 12.5px 14px !important;
}
.border-signup {
  box-shadow: rgba(239, 242, 245, 1) 0px 0px 0px 4px;
  width: 468px;
  height: 64px;
  border-radius: 6px;
  padding: 8px;
  gap: 10px;
}

/* .logo-block-bg {
  background-image: url("assets/images/authpage-image.svg");
} */
                                                                                                                                                                                                                                                                                                         
.step-title {
  position: absolute;
  background: white;
  margin: -10px 12px;
  padding: 0px 5px;
  color: #0B081B;
  font-weight: 600;
  margin-bottom: 8px;
}

.step2-spinner {
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.modal-core {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 540px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 0px;
}

.modal-header {
  padding: 16px 20px;
  border-bottom: 1px solid #e0e0e0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.modal-footer {
  padding: 16px 20px;
  border-top: 1px solid #e0e0e0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  gap: 8px;
}

.status-indicator {
  border-radius: 6px;
  padding: 12px;
  border-width: 1px;
  font-weight: 600;
}

.status-indicator-error {
  border: 1px solid #FCE7F2;
  background-color: #FCE7F2;
  color: #9D1444;
}

.status-indicator-action {
  background-color: #EBF6FF;
  border: 1px solid #EBF6FF;
  color: #003399;
}
